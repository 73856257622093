import React from 'react';

const Header = (props) => (
    <header className="App-header">
        <h1 className="App-title">Welcome to ChessUnit</h1>
        <div>Chess sharing platform</div>
    </header>

);

export default Header;