import React, { Component } from 'react';
import ReactGA from "react-ga";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'

import kokopu from 'kokopu';
import { generateUuid, traverseNodes, jsonToNodeTree, findParent } from './../core/helper';
import Move from './../domain/Move';
import MoveNode from './../domain/MoveNode';
import Chessboard from './../Chessboard';
import MoveList from './../MoveList';
import PromotionModal from './../PromotionModal';
import ShareGameModal from './../ShareGameModal';
import GameHeader from './../GameHeader';

class GameViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            movesTree: new MoveNode(
                'root',
                new Move(-1, -1, 'w', '', '', 'start', 'start')
            ),
            gameHeader: {
                white: 'White player...',
                black: 'Black player...',
                event: 'Event...'
            },
            selectedMove: 'root',
            isEditable: true,
            promotion: {
                showDialog: false,
                moveFrom: undefined,
                moveTo: undefined
            },
            isShareGameDialogVisible: false,
            showShareSummary: false
        };
    }

    componentDidMount = () => {
        this.registerKeyUpHandlers();
    }

    handleMoveClick = (moveIndex) => {
        let moveNode = this.findMove(moveIndex, this.state.movesTree);

        this.setState({
            selectedMove: moveIndex
        });
    }

    handleCommentChanged = (moveIndex, comment) => {
        let rootNode = this.state.movesTree.copy();
        let moveNode = this.findMove(moveIndex, rootNode);
        moveNode.move.comment = comment;

        this.setState({ movesTree: rootNode });
    }

    handleDeleteVariation = (moveIndex) => {
        let rootNode = this.state.movesTree.copy();
        let [parentNode, sideLineId] = findParent(rootNode, moveIndex);

        if (parentNode) {
            parentNode.sideLines = parentNode.sideLines.filter(node => node.id !== sideLineId);
            this.setState(
                { movesTree: rootNode },
                () => this.setState({ selectedMove: parentNode.id})
            );
        } else if (sideLineId === 'root') {
            rootNode.mainLine = undefined;
            this.setState(
                { movesTree: rootNode },
                () => this.setState({ selectedMove: 'root'})
            );
        }
    }

    handlePromoteVariation = (moveIndex) => {
        let rootNode = this.state.movesTree.copy();
        let [parentNode, sideLineId] = findParent(rootNode, moveIndex);

        if (!parentNode || !sideLineId) {
            throw new Error("Promotion not possible");
        }

        let sideLine = parentNode.sideLines.find(item => item.id === sideLineId);

        parentNode.sideLines = parentNode.sideLines.filter(item => item.id !== sideLineId);
        parentNode.sideLines.push(parentNode.mainLine);
        parentNode.mainLine = sideLine;

        this.setState({
            movesTree: rootNode
        });
    }

    addMove = (node, move, mainLine = undefined, sideLines = []) => {
        if (node.move.fenAfter !== move.fenBefore) {
            throw new Error("Move cannot be added to current position");
        }

        // has no next move yet, just add move
        if (!node.mainLine) {
            node.mainLine = new MoveNode(
                generateUuid(),
                move,
                mainLine,
                sideLines
            )

            return node.mainLine.id;
        }
        // has next move, and it is same as current - just return next move
        else if (node.mainLine.move.equals(move)) {
            return node.mainLine.id;
        }
        // has different mainLine move, add new move as variation
        else {
            for (let i = 0; i < node.sideLines.length; i++) {
                if (node.sideLines[i].move.equals(move)) {
                    return node.sideLines[i].id;
                }
            }

            let newNode = new MoveNode(
                generateUuid(),
                move,
                mainLine,
                sideLines
            );

            node.sideLines.push(newNode);
            return newNode.id;
        }
    }

    handleBoardMove = (from, to, promotionTo = undefined) => {
        if (!this.state.isEditable) {
            return;
        }
        let rootNode = this.state.movesTree.copy();
        let currentNode = this.findMove(this.state.selectedMove, rootNode);
        let position = new kokopu.Position('regular', currentNode ? currentNode.move.fenAfter : 'start');
        let moveDescription = position.isMoveLegal(from, to);

        // illegal move
        if(!moveDescription) {
            this.setState ({
                selectedMove: currentNode.id
            });
            return;
        }

        // if is promotion
        if (moveDescription.status === 'promotion') {
            // and promotion piece is already defined, carry on
            if(promotionTo) {
                moveDescription = moveDescription(promotionTo);
            }
            // else display promotion dialog
            else {
                this.setState({
                    promotion: {
                        showDialog: true,
                        moveFrom: from,
                        moveTo: to
                    }
                });
                return;
            }
        }
        else {
            moveDescription = moveDescription();
        }
        let san = position.notation(moveDescription);
        position.play(moveDescription);

        let selectedMove = this.addMove(
            currentNode,
            new Move(-1, -1, moveDescription.color(), san, "", currentNode.move.fenAfter, position.fen())
        );

        this.setState ({
            selectedMove: selectedMove,
            movesTree: rootNode
        });

    }

    findCurrentNode = () => this.findMove(this.state.selectedMove, this.state.movesTree);

    findMove = (moveId, node) => {
        if(node.id === moveId) {
            return node;
        }
        return traverseNodes(node, node => node.id === moveId);
    }

    findPreviousMove = (moveId, node) => {
        return traverseNodes(node, node => node.mainLine && node.mainLine.id === moveId)
    }

    arrowKeyPressed = (event) => {
        if(event.keyCode === 37) {
            this.previousMove();
        }
        else if (event.keyCode === 39) {
            this.nextMove();
        }
    }

    nextMove = () => {
        let move = this.findCurrentNode();
        if(move.mainLine) {
            this.setState({
                selectedMove: move.mainLine.id
            });
        }
    }

    previousMove = () => {
        let move = this.findPreviousMove(this.state.selectedMove, this.state.movesTree);
        if (move) {
            this.setState({
                selectedMove: move.id
            });
        }
    }

    componentDidMount = () => {
        window.addEventListener("keydown", this.arrowKeyPressed);

        let gameId = this.props && this.props.match && this.props.match.params && this.props.match.params.id
            ? this.props.match.params.id
            : undefined;

        let editorId = this.props && this.props.match && this.props.match.params && this.props.match.params.editorId
                    ? this.props.match.params.editorId
                    : undefined;
        let url = '/api/game/' + gameId;
        if (editorId) {
            url = url + '/' + editorId;
        }

        if (gameId) {
            fetch(url)
            .then(response => response.json())
            .then(data => ({ ...data, game: window.atob(data.game)}))
            .then(data => ({ ...data, game: JSON.parse(data.game)}))
            .then(data => ({ ...data, game: jsonToNodeTree(data.game)}))
            .then(data => {
                this.setState({
                    movesTree: data.game,
                    isEditable: data.isEdit,
                    viewerId: gameId,
                    editorId: editorId,
                    gameHeader: {
                        white: data.headers.white,
                        black: data.headers.black,
                        event: data.headers.event
                    },
                })
            });
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener("keydown", this.arrowKeyPressed);
    }

    promotionPieceSelected = piece => {
        let from = this.state.promotion.moveFrom;
        let to = this.state.promotion.moveTo;

        this.setState({
            promotion: {
                showDialog: false,
                moveFrom: undefined,
                moveTo: undefined
            }
        }, this.handleBoardMove(from, to, piece));
    }

    handleGameHeaderChanged = (value, key) => {
        let newGameHeader = {...this.state.gameHeader};
        newGameHeader[key] = value;

        this.setState({
            gameHeader: newGameHeader
        });
    }

    showShareGameDialog = () => {
        this.setState({
            isShareGameDialogVisible: true
        })
    }

    shareGame = (author) => {
        let showShareSummary = false;
        let viewerId = undefined;
        let editorId = undefined;

        let url = '/api/game';
        if (this.state.viewerId && this.state.editorId) {
            url = url + "/" + this.state.viewerId + "/" + this.state.editorId;
        }

        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                author: author,
                headers: {
                    white: this.state.gameHeader.white,
                    black: this.state.gameHeader.black,
                    event: this.state.gameHeader.event
                },
                game: window.btoa(JSON.stringify(this.state.movesTree))
            })
        })
        .then(response => response.json())
        .then(function(myJson) {
            showShareSummary = true;
            viewerId = myJson.viewer_id;
            editorId = myJson.editor_id;
        })
        .then(() => this.setState({
                showShareSummary: showShareSummary,
                viewerId: viewerId,
                editorId: editorId,
                isShareGameDialogVisible: false
            })
        )
        .then(() => ReactGA.event({
            category: 'Game',
            action: 'Game created'
        }));
    }

    onShareDialogHide = () => {
        this.setState({
            isShareGameDialogVisible: false
        })
    }

    render() {
        if (this.state.showShareSummary) {
            let link = "https://share.chessunit.com/game/" + this.state.viewerId;
            return (
                    <Row><Col>
                        <Card>

                          <Card.Body>
                            <Card.Title>Share the game with this link</Card.Title>
                            <Card.Text>
                                <p>To share the game, copy this link and share it with the world.</p>

                                <Row>
                                    <Col lg="8" md="8">
                                        <Form.Control size="lg" type="sharelink" value={link} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="8" md="8">
                                        <br /><br />
                                        <p>Here is administration link. Keep it for yourself, in case you want to edit the game later.</p>
                                        <p>{link}/{this.state.editorId}</p>
                                    </Col>
                                </Row>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                    </Col></Row>
            );
        }

        let selectedNode = this.findCurrentNode();
        let shareButton = this.state.isEditable && this.state.movesTree.mainLine
            ? <Button onClick={this.showShareGameDialog}>Save the game and share it</Button>
            : null;

        return(
            <div>
                <PromotionModal
                    show={this.state.promotion.showDialog}
                    closeHandler={this.promotionPieceSelected} />


                <ShareGameModal
                    show={this.state.isShareGameDialogVisible}
                    onShare={this.shareGame}
                    onHideFn={this.onShareDialogHide} />

                <Row>
                    <Col lg={4} md={6}>
                        <Chessboard
                            fen={selectedNode ? selectedNode.move.fenAfter : 'start'}
                            previousMoveBtnClickHandler={this.previousMove}
                            nextMoveBtnClickHandler={this.nextMove}
                            isEditable={this.state.isEditable}
                            changeHandler={this.handleBoardMove} />
                    </Col>
                    <Col lg={8} md={6}>
                        <Row><Col>
                            <GameHeader
                                headers={this.state.gameHeader}
                                onValueChanged={this.handleGameHeaderChanged} />
                        </Col></Row>
                        <Row className="variation-list-wrapper"><Col>
                            <MoveList
                                moves={this.state.movesTree.mainLine}
                                moveClickHandler={this.handleMoveClick}
                                commentChangedHandler={this.handleCommentChanged}
                                deleteVariationHandler={this.handleDeleteVariation}
                                promoteVariationHandler={this.handlePromoteVariation}
                                selectedMove={this.state.selectedMove}
                                variationDepth={0}
                                isEditable={this.state.isEditable}
                                moveOffset={0} />
                        </Col></Row>

                        <Row><Col>
                            {shareButton}
                        </Col></Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default GameViewer