

export default class MoveNode {

    constructor (id, move, mainLine = undefined, sideLines = []) {
        this.id = id;
        this.move = move;
        this.mainLine = mainLine;
        this.sideLines = sideLines;
    }

    copy () {
        return new MoveNode(
            this.id,
            this.move.copy(),
            this.mainLine ? this.mainLine.copy() : undefined,
            this.sideLines.map(sideLine => sideLine.copy())
        )
    }
}