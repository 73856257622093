import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


class ShareGameModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nickname: '',
        };
    }

    onShare = () => {
        this.props.onShare(this.state.nickname);
    }

    onNicknameChanged = (evt) => {
        this.setState({
            nickname: evt.target.value
        });
    }

    isSubmitDisabled = () => {
        return !(this.state.nickname && this.state.nickname.length > 0);
    }

    render() {
        let isSubmitDisabled = this.isSubmitDisabled();

        return(
            <Modal show={this.props.show}>
                <Modal.Header>
                    <Modal.Title>Save game and share</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container><Row><Col>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Your nickname</Form.Label>
                                <Form.Control onChange={this.onNicknameChanged} type="email" placeholder="Enter nickname" />
                                <Form.Text className="text-muted">
                                    Enter your name, which will be showed to the viewers of the game.
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </Col></Row></Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container><Row><Col>
                        <Button className="mx-2" variant="secondary" onClick={this.props.onHideFn}>Cancel</Button>
                        <Button className="mx-2" disabled={isSubmitDisabled} variant="success" onClick={this.onShare}>Share</Button>
                    </Col></Row></Container>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ShareGameModal;