import React, { Component } from 'react';
import ContentEditable from 'react-contenteditable'

class EditableInput extends Component {

    constructor() {
        super()
        this.inputRef = React.createRef();
    };

    handleChange = evt => {
        if(evt.keyCode === 13) {
            evt.target.blur();
        }
        if(evt.keyCode === 37 || evt.keyCode === 39) {
            evt.stopPropagation();
        }
    }

    render() {
        return (
                <ContentEditable
                    innerRef={this.blackPlayerRef}
                    html={this.props.headerValue}   // innerHTML of the editable div
                    disabled={false}                // use true to disable editing
                    onKeyDown={this.handleChange}   // handle innerHTML change
                    tagName={this.props.tagName || 'div'} // Use a custom HTML tag (uses a div by default)
                    onBlur={(evt) => this.props.onValueChanged(evt.target.innerText, this.props.headerName)}
                    className="editable-input"
                />
        );
    }
}

export default EditableInput;