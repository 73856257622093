import React from 'react';
import EditableInput from './EditableInput'

const GameHeader = (props) => (
            <div>
                <div>
                    <EditableInput
                        headerName='white'
                        headerValue={props.headers.white}
                        onValueChanged={props.onValueChanged}
                        tagName='span'
                    />
                    <span> - </span>
                    <EditableInput
                        headerName='black'
                        headerValue={props.headers.black}
                        onValueChanged={props.onValueChanged}
                        tagName='span'
                    />
                </div>
                <EditableInput
                    headerName='event'
                    headerValue={props.headers.event}
                    onValueChanged={props.onValueChanged}
                />
            </div>
        );
export default GameHeader;