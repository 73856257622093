import React, { Component } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

class Move extends Component {

    constructor(props) {
        super(props);
        this.moveRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.props.isSelected) {
            this.moveRef.current.scrollIntoView({ block: "center" });
        }
    }

    handleCommentClick = (e, data) => {
        this.props.commentChangedHandler(this.props.id, ' ');
    }

    handleDeleteClick = (e, data) => {
        this.props.deleteVariationHandler(this.props.id);
    }

    handlePromoteVariationClick = (e, data) => {
        this.props.promoteVariationHandler(this.props.id);
    }

    render() {
        const className = this.props.isSelected ? "move-wrapper selected" : "move-wrapper";

        return(
            <div className={className} onClick={() => this.props.moveClickHandler(this.props.id)} ref={this.moveRef}>
                <span>{this.props.moveNumberString}</span>

                <ContextMenuTrigger id={this.props.id + '_cm'} renderTag='span'>
                    <div className='move'>
                        {this.props.moveString}
                    </div>
                </ContextMenuTrigger>

                <ContextMenu id={this.props.id + '_cm'}>
                    <MenuItem disabled={!this.props.isEditable} onClick={this.handleCommentClick}>
                        Add comment
                    </MenuItem>
                    <MenuItem disabled={!this.props.isPromotionEnabled} onClick={this.handlePromoteVariationClick}>
                        Promote variation
                    </MenuItem>
                    <MenuItem disabled={!this.props.isDeleteEnabled} onClick={this.handleDeleteClick}>
                        Delete variation
                    </MenuItem>
                  </ContextMenu>

            </div>
        );
    }
}

export default Move;