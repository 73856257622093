import React, { Component } from 'react';
import ContentEditable from 'react-contenteditable'

class Comment extends Component {

    constructor() {
        super()
        this.contentEditable = React.createRef();
    };

    handleChange = evt => {
        if(evt.keyCode === 13) {
            evt.target.blur();
        }
        if(evt.keyCode === 37 || evt.keyCode === 39) {
            evt.stopPropagation();
        }
    };

    componentDidUpdate() {
        if (this.props.comment === " ") {
            this.contentEditable.current.focus();
        }
    }

    handleBlur = evt => {
        this.props.commentChangedHandler(this.props.id, evt.target.innerText);
    }

    render() {

        return (this.props.comment
            ? this.props.isEditable
                ? <ContentEditable
                      innerRef={this.contentEditable}
                      html={this.props.comment !== " " ? this.props.comment : ""} // innerHTML of the editable div
                      disabled={false}       // use true to disable editing
                      onKeyDown={this.handleChange} // handle innerHTML change
                      tagName='span' // Use a custom HTML tag (uses a div by default)
                      className='move-comment'
                      onBlur={this.handleBlur}
                    />
                : <span className='move-comment'>{this.props.comment}</span>
            : null)
    }
}

export default Comment;