import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


class PromotionDialog extends Component {

    render() {
        return(
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header>
                    <Modal.Title>Promote to</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container><Row><Col>
                        <Button className="mx-2" variant="primary" onClick={() => this.props.closeHandler('q')}>Queen</Button>
                        <Button className="mx-2" variant="secondary" onClick={() => this.props.closeHandler('r')}>Rook</Button>
                        <Button className="mx-2" variant="success" onClick={() => this.props.closeHandler('n')}>Knight</Button>
                        <Button className="mx-2" variant="warning" onClick={() => this.props.closeHandler('b')}>Bishop</Button>
                    </Col></Row></Container>
                </Modal.Body>
            </Modal>
        );
    }
}

export default PromotionDialog;