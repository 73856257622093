import Move from './../domain/Move';
import MoveNode from './../domain/MoveNode';

export const generateUuid = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
};

export const jsonToNodeTree = (json) => {
    return new MoveNode(
        json.id,
        new Move(
            json.move.from,
            json.move.to,
            json.move.color,
            json.move.san,
            json.move.comment,
            json.move.fenBefore,
            json.move.fenAfter
        ),
        json.mainLine ? jsonToNodeTree(json.mainLine) : undefined,
        json.sideLines.map(sideLine => jsonToNodeTree(sideLine))
    );
}

export const findParent = (root, id)  => {
    let node = undefined;

    do {
        node = traverseNodes(
            root,
            traversedNode => traversedNode.mainLine && traversedNode.mainLine.id === id
        );
        id = node ? node.id : id;
    } while (node !== undefined);

    let branchingParent = traverseNodes(
        root,
        traversedNode => traversedNode.sideLines.find(sideLine => sideLine.id === id)
    );

    return [branchingParent, id];
}

// maybe traverseNodes should be renamed to findNode
export const traverseNodes = (node, equalityFn) => {
    if (!node) {
        return undefined;
    }
    else if (equalityFn(node)) {
        return node;
    }
    else {
        let result;
        for (let i = 0; i < node.sideLines.length; i++) {
            result = traverseNodes(node.sideLines[i], equalityFn);
            if (result) {
                return result;
            }
        }

        return traverseNodes(node.mainLine, equalityFn);
    }
}
