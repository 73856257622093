
export default class Move {

    constructor (from, to, color, san, comment, fenBefore, fenAfter) {
        this.from = from;
        this.to = to;
        this.color = color;
        this.san = san;
        this.comment = comment;
        this.fenBefore = fenBefore;
        this.fenAfter = fenAfter;
    }

    equals (other) {
        return this.from === other.from
            && this.to === other.to
            && this.color === other.color
            && this.san === other.san
            && this.comment === other.comment
            && this.fenBefore === other.fenBefore
            && this.fenAfter === other.fenAfter;
    }

    copy () {
        return new Move(this.from, this.to, this.color, this.san, this.comment, this.fenBefore, this.fenAfter);
    }

}