import React, { Component } from 'react';
import * as cg from 'chessground';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

class Chessboard extends Component {

    constructor(props) {
        super(props);
        this.boardElement = React.createRef();

        this.state = {
            orientation: 'white'
        };
    }

    componentDidMount() {
        this.displayBoard();
    }

    componentDidUpdate () {
        this.displayBoard();
    }

    moveMade = () => {
        this.props.changeHandler(this.chessground.state.lastMove[0], this.chessground.state.lastMove[1]);
    }

    flipBoard = () => {
        let newOrientation = this.state.orientation === 'white' ? 'black' : 'white';
        this.setState({
            orientation: newOrientation
        });
    }

    displayBoard () {
        this.chessground = new cg.Chessground(
            this.boardElement.current, {
              fen: this.props.fen,
              orientation: this.state.orientation,
              animation: {
                  enabled: true
              },
              events: {
                  move: this.moveMade
              },
              viewOnly: !this.props.isEditable
            }
        );
    }

    render() {
        return(
            <div>
                <Row><Col>
                    <div className="blue merida">
                        <div id='board' ref={this.boardElement}>Board comes here.</div>
                    </div>
                </Col></Row>

                <Row className='mt-3'><Col>
                    <Button className='mr-1 btn-prev' variant="secondary" onClick={this.props.previousMoveBtnClickHandler}>&lt;</Button>
                    <Button className='mr-1 btn-next' variant="secondary" onClick={this.props.nextMoveBtnClickHandler}>&gt;</Button>
                    <Button className='mr-1 btn-flip' variant="secondary" onClick={this.flipBoard}>Flip</Button>
                </Col></Row>
            </div>
        )
    }
}

export default Chessboard