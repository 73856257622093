import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

class WelcomePage extends React.Component {

    constructor(props) {
        super(props)
    };

    handleClick = () => {
        this.props.history.push('/game');
    }

    render () {
        return (
            <Jumbotron fluid>
                <Container>
                    <h1>Share chess games!</h1>
                    <p>
                        This is a simple application for creating, commenting and sharing your chess games.
                        <ul>
                            <li>Easy to use</li>
                            <li>No account required</li>
                            <li>Share chess games with your friends</li>
                        </ul>
                    </p>
                    <p>
                        Try it right now.
                    </p>
                    <p className="text-center">
                        <Button size='lg' onClick={this.handleClick}>Create chess game</Button>
                    </p>
                </Container>
            </Jumbotron>
        )};

}

export default withRouter(WelcomePage);