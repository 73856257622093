import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import GameViewer from './GameViewer';
import About from './About';
import WelcomePage from './WelcomePage';
import Header from './../Header';
import { withTracker } from './../GATracker';

import Container from 'react-bootstrap/Container'

class App extends Component {

    render() {
        return(
            <div>
                <Header />
                <Container>
                    <Router>
                        <Route path="/" exact component={ withTracker(WelcomePage) } />
                        <Route path="/game/:id?/:editorId?" component={ withTracker(GameViewer) } />
                        <Route path="/about" component={ withTracker(About) } />
                    </Router>
                </Container>
            </div>
        )
    };
}

export default App;