import React, { Component } from 'react';
import Move from './Move';
import Comment from './Comment';

class MoveList extends Component {

    render() {
        let isMoveAfterVariation = false;
        let elements = [];
        let sidelines = [];
        let node = this.props.moves;
        let counter = this.props.moveOffset;
        let className = `variation-wrapper depth-${this.props.variationDepth}`;

        while (node) {
            let moveNumberString = '';

             if (node.move.color === 'w' || counter === this.props.moveOffset || isMoveAfterVariation) {
                moveNumberString = node.move.color === 'w'
                    ? `${counter / 2 + 1}.`
                    : `...${(counter + 1) / 2}.`;
             }
             isMoveAfterVariation = false;
            elements.push(
                <Move
                    moveNumberString={moveNumberString}
                    moveString={node.move.san}
                    moveClickHandler={this.props.moveClickHandler}
                    key={node.id}
                    id={node.id}
                    isSelected={node.id === this.props.selectedMove}
                    comment={node.move.comment}
                    commentChangedHandler={this.props.commentChangedHandler}
                    deleteVariationHandler={this.props.deleteVariationHandler}
                    promoteVariationHandler={this.props.promoteVariationHandler}
                    isEditable={this.props.isEditable}
                    isDeleteEnabled={this.props.isEditable && this.props.variationDepth > 0}
                    isPromotionEnabled={this.props.isEditable && this.props.variationDepth > 0}
                />
            );

            elements.push(
                <Comment
                    comment={node.move.comment}
                    commentChangedHandler={this.props.commentChangedHandler}
                    isEditable={this.props.isEditable}
                    id={node.id}
                    key={node.id + '_comment'} />
            );

            if (sidelines.length > 0) {
                elements = elements.concat(sidelines);
                isMoveAfterVariation = true;
                sidelines = [];
            }

            if(node.sideLines && node.sideLines.length > 0) {
                node.sideLines.forEach(node =>
                    sidelines.push(
                        <MoveList
                            key={node.id + "_variation"}
                            moves={node}
                            moveClickHandler={this.props.moveClickHandler}
                            selectedMove={this.props.selectedMove}
                            variationDepth={this.props.variationDepth + 1}
                            moveOffset={counter+1}
                            commentChangedHandler={this.props.commentChangedHandler}
                            deleteVariationHandler={this.props.deleteVariationHandler}
                            promoteVariationHandler={this.props.promoteVariationHandler}
                            isEditable={this.props.isEditable}
                        />
                    )
                );
            }

            node = node.mainLine;
            counter ++
        };

        return(<div className={className}>{elements}</div>);
    }
}

export default MoveList;